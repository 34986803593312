/* Stylesheets */
import './scss/vendors/bootstrap.scss'; // ref: https://getbootstrap.com/docs/5.2/customize/sass/#importing
// import './scss/vendors/swiper.scss'; // ref: https://swiperjs.com/get-started#installation
// import './scss/vendors/hamburgers.scss'; // ref: https://jonsuh.com/hamburgers/#sass
import './scss/fonts.scss';
import './scss/style.scss';


/* Scripts */
// import $ from 'jquery';
import { Modal, Collapse } from 'bootstrap'; 
// ref: https://getbootstrap.com/docs/5.2/getting-started/webpack/#import-bootstrap - Point 3.
// import Swiper, { } from 'swiper'; // ref: https://swiperjs.com/get-started#install-from-npm
// import { library, dom } from '@fortawesome/fontawesome-svg-core'; // ref: https://fontawesome.com/docs/apis/javascript/import-icons#package-names
// import { fas } from '@fortawesome/pro-solid-svg-icons';
// import { fab } from '@fortawesome/pro-solid-svg-icons';
// import { far } from '@fortawesome/pro-regular-svg-icons';
// import { fal } from '@fortawesome/pro-light-svg-icons';
// import { fat } from '@fortawesome/pro-thin-svg-icons';
// import { fad } from '@fortawesome/pro-duotone-svg-icons';
// import { fass } from '@fortawesome/sharp-solid-svg-icons';
// library.add(fas, far, fal, fat, fad, fass);
// dom.watch();
//import * as Custom from './custom.js';

//import { showModal, showLoading, hideLoading, scrollToId } from './custom.js';


/* Init Functions on DOMContentLoaded */
const ready = (callback) => {
    if (document.readyState != "loading") callback();
    else document.addEventListener("DOMContentLoaded", callback);
};

const isDebug = process.env.NODE_ENV !== 'production';

/* Variables and functions */
const initWebpackVersion = () => {
    isDebug && console.log(`%c${DESCRIPTION}`, `color: #9ac456; font-size: 18px; font-weight: 700;`, `Version ${VERSION} - Development Environment`);
};

//Show/Hide Loading
//window.showLoading = Custom.showLoading;
//window.hideLoading = Custom.hideLoading;

//Scroll
//window.scrollToId = Custom.scrollToId;
//window.scrollToElm = Custom.scrollToElm;

/* Show Modal */
function showModal() {
    const errorModal = new Modal(document.getElementById('popErrorModal'))
    errorModal.show();
}
//window.showModal = showModal;

function popErrore(error) {
    const errorText = document.getElementById('modalBodyText');
    errorText.innerHTML = error;

    showModal();
}
window.popErrore = popErrore;

//window.showError = Custom.showError;
//window.invalidAccessibility = Custom.invalidAccessibility;

/* Ready callback function */
ready(() => {
    initWebpackVersion();
});




